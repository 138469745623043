// src/App.js

import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from './logo.svg'; 
import { Container, Row, Col, Button, Spinner, Alert, Card, ToggleButtonGroup, ToggleButton, InputGroup, FormControl } from 'react-bootstrap';
import ConnectButton from './components/ConnectButton';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css'; // Import your custom CSS


import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import Dashboard from './components/Dashboard';
import Nodes from './components/Nodes';
import BuyToken from './components/BuyToken';
import Staking from './components/Staking';





function App() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();


  if(!isConnected) {
    return (<Container style={{  minHeight: '100vh', marginTop:"30px" }}>
    <ConnectButton />
    
    </Container>)
  }

   return (
    <Router>
      <Container style={{  minHeight: '100vh', marginTop:"30px" }}>
        <Row className="mb-3">
            <Col sm={12} md={2}>
                <h1 style={{ color: 'white' }}><img src={Logo} style={{width:"130px"}} /></h1>
                </Col>
                <Col sm={12} md={7}>
                <Link to="/" className="buton menuBtn">Home</Link>
                <Link to="/nodes" className="buton menuBtn">Nodes</Link>
          <Link to="/buy-token" className="buton menuBtn">Token</Link>
          <Link to="/staking" className="buton menuBtn">Stake</Link>
                  </Col>
                <Col sm={12} md={3}>
         
                  <>
                  <ConnectButton />
                  
                   
                  </>
               
            </Col>
               
        </Row>
     
        <Routes>
        <Route path="/staking" element={<Staking provider={walletProvider} account={address} />} />
        <Route path="/buy-token" element={<BuyToken provider={walletProvider} account={address} />} />
        <Route path="/nodes" element={<Nodes provider={walletProvider} account={address} />} />
          <Route path="/" element={<Dashboard provider={walletProvider} address={address} />} />
        
        </Routes>
      </Container>
    </Router>
  );
}

/*
function App() {
    const { provider, account, connectWallet, smallAccount, ethBalance, usdtBalance, tokenBalance } = useWallet();
    const { collections, ownedNFTs, fetchOwnedNFTs } = useContract(provider);

    
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    


    useEffect(() => {
      fetchOwnedNFTs();  
  }, []);
 
    const renderOwnedNFTs = () => {

      return ownedNFTs.map((nft, index) => (
          <Col key={index} sm={12} md={6} lg={4} xl={3} className="mb-4">
            <Node provider={provider} 
                  account={account} 
                  collectionAddress={nft.collectionAddress} 
                  tokenId={nft.tokenId} 
                  unclaimedRewards={nft.unclaimedRewards}
                  name={nft.name}
                  imageURL={nft.imageURL}
                  
                  />
             
          </Col>
      ));
  };




  if (loading) {
    return (
        <div className="loaderScreen text-center">
          <br />
          <img src={Logo} style={{width:"150px", marginTop:"100px"}} /><br />
            <Spinner animation="border" role="status" className='loaderBig' />
            <p className='loaderMsg'>{txMessage}</p>
        </div>
    );
}

    return (
      <Router>
        <Container style={{  minHeight: '100vh', marginTop:"30px" }}>
          <Row className="mb-3">
              <Col sm={12} md={2}>
                  <h1 style={{ color: 'white' }}><img src={Logo} style={{width:"130px"}} /></h1>
                  </Col>
                  <Col sm={12} md={7}>
                  <Link to="/" className="buton menuBtn">Home</Link>
                  <Link to="/nodes" className="buton menuBtn">Nodes</Link>
            <Link to="/buy-token" className="buton menuBtn">Token</Link>
            <Link to="/staking" className="buton menuBtn">Stake</Link>
                    </Col>
                  <Col sm={12} md={3}>
                  {!account && (
                      <Button variant="primary" onClick={connectWallet}>
                          Connect Wallet
                      </Button>
                  )}
                  {account && (
                    <>
                      <p style={{ color: 'white', marginTop:"12px", textAlign:"right" }}>
                        <div className='status-circle green-circle'></div>
                        <small>{smallAccount}</small>
                      </p>
                     
                    </>
                  )}
              </Col>
                 
          </Row>
       
          <Routes>
            <Route path="/buy-token" element={<BuyToken provider={provider} account={account} />} />
            <Route path="/staking" element={<Staking provider={provider} account={account} />} />
            <Route path="/nodes" element={<Nodes provider={provider} account={account} />} />
            <Route path="/" element={<Dashboard provider={provider} account={account} />} />
          
          </Routes>
        </Container>
      </Router>
    );
}
*/
export default App;
