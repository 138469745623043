// src/components/Staking.js
import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Spinner, Button, Card } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import MainContractABI from '../abis/MainContractABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";

import logoToken from '../logoToken.svg';
import Logo from '../logo.svg';

const Staking = ({ provider, account }) => {
    const [tokenBalance, setTokenBalance] = useState('0');
    const [stakes, setStakes] = useState([]);
    const [amountToStake, setAmountToStake] = useState('');
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [stakedAmount, setStakedAmount] = useState('0');



  
    async function getBalance() {
   
    
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
   

        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));



      }


    useEffect(() => {
        if (!provider || !account) return;

      

        fetchStakes();
        getBalance();
    }, [provider, account]);
    const fetchStakes = async () => {
        setLoading(true);
        try {
            const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.mainContract, MainContractABI, signer);
            const stakesData = await contract.getUserStakes(account);
            setStakes(stakesData.map((stake, index) => ({
                amount: formatUnits(stake.amount.toString(), 18),
                timestamp: stake.timestamp.toNumber(),
                claimed: stake.claimed,
                index
            })));
        } catch (error) {
            console.error("Failed to fetch stakes", error);
        } finally {
            setLoading(false);
        }
    };
    const handleStake = async () => {
        if (!provider || !account) return;

        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            setLoading(true);
            setTxMessage("Approving token transfer...");

            const tokenContract = new Contract(
                SETTINGS.tokenAddress,
                ERC20_ABI,
                signer
            );

            const amountToStakeWei = formatUnits(amountToStake.toString(), 18);
            
            const approveTx = await tokenContract.approve(
                SETTINGS.mainContract,
                amountToStakeWei
            );
            await approveTx.wait();

            setTxMessage("Staking tokens...");

            const stakingContract = new Contract(
                SETTINGS.mainContract,
                MainContractABI,
                signer
            );

            const stakeTx = await stakingContract.stake(amountToStakeWei);
            await stakeTx.wait();

            setTxMessage("Tokens staked successfully!");
            console.log("Tokens staked!");

            await fetchStakes();
        } catch (error) {
            console.error("Staking failed", error);
            setTxMessage("Staking failed. Please try again.");
        } finally {
            
            setTimeout(() => setLoading(""), 5000);
        }
    };

    const handleClaim = async (stakeIndex) => {
        if (!provider || !account) return;
        
        try {
            setLoading(true);
            setTxMessage("Claiming rewards...");
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(
                SETTINGS.mainContract,
                MainContractABI,
                signer
            );
    
            // Claim rewards for the specific stake
            await contract.claim(stakeIndex);
            setTxMessage("Rewards successfully claimed!");
            
        } catch (error) {
            console.error("Claiming failed", error);
            setTxMessage("Claiming failed. Please try again.");
        } finally {
            setLoading(false);
            setTimeout(() => setTxMessage(""), 5000);
        }
    };
    
    const getProgress = (timestamp) => {
        const now = Math.floor(Date.now() / 1000);
        const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
        const timePassed = now - timestamp;
        const progress = (timePassed / thirtyDaysInSeconds) * 100;
        
        return Math.min(progress, 100); // Ensure progress does not exceed 100%
    };


    const isClaimable = (timestamp) => {
        const now = Math.floor(Date.now() / 1000);
        const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
        return (now - timestamp) >= thirtyDaysInSeconds;
    };
    
    

    if (loading) {
        return (
            <div className="loaderScreen text-center">
            <br />
            <img src={Logo} style={{width:"200px", marginTop:"100px"}} /><br />
              <Spinner animation="border" role="status" className='loaderBig' />
              <p className='loaderMsg'>{txMessage}</p>
          </div>
        );
    }

    return (
        <div>
           <Row>
            <Col sm={12} md={2}></Col>
            <Col sm={12} md={8}>
        <div className="buy-token-container">
            <Row>
                <Col>
                <div className="buy-token-header text-center">
            <h2 style={{textAlign:"center", width:"100%"}}>Stake</h2>
            </div>
                  
                    <div className="swap-form">
                    <h5>Stake {SETTINGS.tokenSymbol}</h5>
                <div className="input-group">
                    <input
                        type="text"
                        value={amountToStake}
                        onChange={(e) => setAmountToStake(e.target.value)}
                        placeholder="Amount to stake"
                        style={{width:"70%", float:"left"}}
                        
                    />
                     <Button variant="primary" 
                     style={{width:"30%", float:"left"}}
                     className='buton' 
                     onClick={handleStake}>Stake</Button>

                    </div>
                    <p style={{ color: 'white', marginTop:"20px" }}>
                    <img src={logoToken} className='tokenIconColor'   style={{width:"25px", marginRight:"15px", marginTop:"-5px"}} />
                  
                      {tokenBalance}<small> {SETTINGS.tokenSymbol} </small>
                      </p>
                    </div>
                   
                        
                       
                   
               
                   <div className="staking-container">
                   <hr />
                        <h4>Your Stakes</h4>
                        {stakes.map((stake, index) => (
                            <Card key={index} className="mb-3 pokemon-card">
                                <Row>
                                <Col sm={4} md={4}>
                                <h5 style={{marginTop:"15px"}}>
                                <img src={logoToken} className='tokenIconColor'   style={{width:"25px", marginRight:"10px", marginLeft:"10px", marginTop:"-5px"}} />
                  
                                     {stake.amount} 
                                     <small> {SETTINGS.tokenSymbol}</small>
                                     </h5>
                                </Col>
                                <Col sm={4} md={4}>
                                <ProgressBar 
                                style={{marginTop:"15px"}}
                                    now={getProgress(stake.timestamp)}
                                    label={`${getProgress(stake.timestamp)}%`} />
                                     </Col>
                                <Col sm={4} md={4}>
                                <Button variant="success" className="buton" size="sm" onClick={() => handleClaim(index)} disabled={!isClaimable(stake.timestamp) || stake.claimed}>
                                    {stake.claimed ? "Claimed" : "Claim"}
                                </Button>
                                </Col>
                                </Row>
                            </Card>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
        </Col>
        </Row>
        </div>
    );
};

export default Staking;
