// src/components/Nodes.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Spinner, Alert } from 'react-bootstrap';
import { ethers } from 'ethers';
import MainContractABI from '../abis/MainContractABI';
import NodesABI from '../abis/NodesABI';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { useContract } from '../hooks/useContract';




const Node = ({ collectionAddress, tokenId, unclaimedRewards, name, imageURL }) => {
   
    
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState('');


    useEffect(() => {
       
    }, []);
    

    
    
    
    return (
           <Card className="nft-card h-100 pokemon-card">
                  <Card.Img variant="top" src={imageURL} />
                  <Card.Body>
                      <Card.Title>
                        <div className='pokemon-card-id'><small>#</small> {tokenId}</div> 
                        <h6 className='pokemon-card-title'> {name}</h6> 
                        </Card.Title>
                      <Card.Text>
                        <h5 className='text-center'>
                            <small>Unclaimed<br /> </small>
                          {unclaimedRewards}
                        
                        </h5>
                      <Button variant="primary"  className='buton'>
                                        Claim
                                    </Button>
                      </Card.Text>
                  </Card.Body>
              </Card>
    );
};

export default Node;
