import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Alert, Spinner,Button } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import MainContractABI from '../abis/MainContractABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.svg'; 
import Logo from '../logo.svg'; 



const BuyToken = ({ provider, account }) => {
    const [ethBalance, setEthBalance] = useState('0');
    const [usdtBalance, setUsdtBalance] = useState('0');
    const [tokenBalance, setTokenBalance] = useState('0');
    const [presales, setPresales] = useState([]);
    const [selectedPresaleIndex, setSelectedPresaleIndex] = useState(0);
    const [amountToBuy, setAmountToBuy] = useState('');
    const [amountToRecive, setAmountToRecive] = useState('');
    const [activePresale, setActivePresale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");

    async function getBalance() {
   
    
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
        const USDTBalance = await USDTContract.balanceOf(account);
        const USDTDecimals = await USDTContract.decimals();
        setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));

        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));

        const balance = await ethersProvider.getBalance(account);
        setEthBalance(formatUnits(balance, 18));

      }

    // Fetch presale details from the contract
    useEffect(() => {
        const fetchPresales = async () => {
            if (!provider || !account) return;
            const ethersProvider = new BrowserProvider(provider);
            const contract = new Contract(SETTINGS.mainContract, MainContractABI, ethersProvider);
            console.log(1);
            const pArr = await contract.getAllPresales();
            console.log(pArr);
            console.log(pArr.length);
            
            let data = [];
            for(let i=0;i<pArr.length;i++){
                data.push({
                    tokenAmount:pArr[i].tokenAmount.toString(),
                    tokenAmountDec:formatUnits(pArr[i].tokenAmount.toString(), 18).toString(),
                    price:pArr[i].price.toString(),
                    priceDec:formatUnits(pArr[i].price.toString(), 18).toString(),
                    soldAmount:pArr[i].soldAmount.toString(),
                    isActive:pArr[i].isActive,
                })
            }
            setPresales(data);
            const active = data.find((presale) => presale.isActive);
            setActivePresale(active);
            setSelectedPresaleIndex(data.indexOf(active));
        };
        fetchPresales();
        getBalance();
    }, [provider, account]);

    const _setAmountToBuy = async (amount) => {
        setAmountToBuy(amount);
        const price = parseFloat(activePresale.priceDec);
        const tokensToRecive = parseFloat(amount) / price;
        setAmountToRecive(tokensToRecive.toFixed(4));

    };
 const handleBuyToken = async () => {
       

        try {
            setLoading(true);
            setTxMessage("Approving USDT transfer...");
            const ethersProvider = new BrowserProvider(provider);
            const usdtContract = new Contract(
                SETTINGS.usdtAddress,
                ERC20_ABI,
                ethersProvider.getSigner()
            );

            // Convert the amount to buy to wei (USDT has 6 decimals)
            const amountToBuyWei = formatUnits(amountToBuy, 6);

            // Approve the token sale contract to spend USDT
            const approveTx = await usdtContract.approve(
                SETTINGS.mainContract,
                amountToBuyWei
            );
            await approveTx.wait();

            setTxMessage("Purchasing tokens...");

            // Now call the buyToken function
            const tokenSaleContract = new Contract(
                SETTINGS.mainContract,
                MainContractABI,
                ethersProvider.getSigner()
            );

            const buyTx = await tokenSaleContract.buyToken(
                selectedPresaleIndex,
                amountToBuyWei
            );
            await buyTx.wait();

            setTxMessage("Tokens purchased successfully!");
            console.log("Tokens purchased with USDT!");

            // Update UI after purchase
            // Fetch updated presales information, etc.

        } catch (error) {
            console.error("Transaction failed!", error);
            setTxMessage("Transaction failed. Please try again.");
        } finally {
            setLoading(false);
            // Reset or update the message after a delay
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    if (loading) {
        return (
            <div className="loaderScreen text-center">
              <br />
              <img src={Logo} style={{width:"200px", marginTop:"100px"}} /><br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    if (presales.length === 0) {
        return (
            <div className="loaderScreen text-center">
            <br />
            <img src={Logo} style={{width:"200px", marginTop:"100px"}} /><br />
              <Spinner animation="border" role="status" className='loaderBig' />
              <p className='loaderMsg'>{txMessage}</p>
          </div>
        );
    }

    if (!activePresale) {
        return <Alert variant="warning">There are currently no active presales. Please check back later.</Alert>;
    }

    return (
        <div>
           <Row>
            <Col sm={12} md={2}></Col>
            <Col sm={12} md={8}>
            <div>
            <div className="buy-token-header text-center">
            <h2>Buy</h2>
            </div>
            <div className="token-exchange-info">
                <p> <img src={logoToken}  style={{width:"35px", marginRight:"5px",  marginleft:"30px", marginTop:"-5px"}} />
                    1 {SETTINGS.tokenSymbol} = 
                     <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"  style={{width:"35px", marginRight:"5px",  marginLeft:"10px", marginTop:"-5px"}} />
                    
                    {activePresale.priceDec} USDT</p>
            </div>
            
            <div className="balance-info">
                Available in presale: {activePresale.tokenAmountDec} {SETTINGS.tokenSymbol}
            </div>
            <div className="swap-form">
                <div className="input-group">
                    <input
                        type="text"
                        value={amountToBuy}
                        onChange={(e) => _setAmountToBuy(e.target.value)}
                        placeholder="Amount in USDT"
                    />
                    <button className="max-button">to</button>
                    <input type="text" placeholder="{SETTINGS.tokenSymbol} to receive" 
                        value={amountToRecive} />
                    <span><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"  style={{width:"18px", marginRight:"10px",  marginLeft:"10px", marginTop:"-2px"}} /> 
                    <small>{usdtBalance} USDT</small></span>
                </div>
                <div className="gas-info">
                    Some {SETTINGS.nativeSymbol} is reserved for gas. The actual amount used will depend on the network
                </div>
                <Button className="buton" onClick={() => handleBuyToken()}>SWAP NOW</Button>
                
                <center><span style={{marginTop:"10px", display:"block"}}><img src={SETTINGS.nativeIcon} style={{width:"18px", marginRight:"10px",  marginLeft:"10px", marginTop:"-2px"}} /> 
                    <small>{ethBalance} {SETTINGS.nativeSymbol}</small></span></center>
            </div>
        </div>
        <hr />
            <center><h4 style={{ color: 'white' }}>Presales</h4></center>
            {presales.map((presale, index) => (
                <Card key={index} className="mb-3 pokemon-card">
                    <Card.Body>
                        <Card.Title>Presale {index + 1}</Card.Title>
                        <Card.Text>
                            Price: {formatUnits(presale.price.toString(), 'ether')} USDT
                            <br />
                            Available: 
                            {formatUnits(presale.tokenAmount.toString(), 'ether')} {SETTINGS.tokenSymbol}

                            <br />
                            Status: {presale.isActive ? "Active" : "Inactive"}
                        </Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </Col>
        </Row>
       
           
    
        </div>
    );
};

export default BuyToken;
