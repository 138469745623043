// src/App.js

import React, { useEffect, useState } from 'react';


import Logo from '../logo.svg'; // ABI of your contract
import logoToken from '../logoToken.svg'; // ABI of your contract
import { useContract } from '../hooks/useContract';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import { Container, Row, Col, Button, Spinner, Alert, Card, ToggleButtonGroup, ToggleButton, InputGroup, FormControl } from 'react-bootstrap';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import Node from '../components/Node';



function Dashboard({ provider, address }) {
    const { collections, ownedNFTs, fetchOwnedNFTs } = useContract(provider);


    const [usdtBalance, setUsdtBalance] = useState('0');
    const [tokenBalance, setTokenBalance] = useState('0');


  
    
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");

  
    async function getBalance() {
   
    
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
        const USDTBalance = await USDTContract.balanceOf(address);
        const USDTDecimals = await USDTContract.decimals();
        setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));

        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(address);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));



      }

    useEffect(() => {
        getBalance();
  }, []);
 
    const renderOwnedNFTs = () => {

      return ownedNFTs.map((nft, index) => (
          <Col key={index} sm={12} md={6} lg={4} xl={3} className="mb-4">
            <Node 
                  collectionAddress={nft.collectionAddress} 
                  tokenId={nft.tokenId} 
                  unclaimedRewards={nft.unclaimedRewards}
                  name={nft.name}
                  imageURL={nft.imageURL}
                  
                  />
             
          </Col>
      ));
  };


    return (
        <>
        <div className="">
     <div className="buy-token-header">
     
     </div>
    
     
     </div>
  
   
   <div>

               
 
         
             <p className='balanceDisplay'>
             <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"   />
           
               {usdtBalance}<small> USDT </small>
               </p>
      
               <p className='balanceDisplay'>
             <img src={logoToken}   />
           
               {tokenBalance}<small> {SETTINGS.tokenSymbol} </small>
               </p>
   
         </div>
         <Row className="mb-3">
     <Col>
     
   <div >
   <h4 style={{ color: 'white' }}>My nodes</h4>
         {ownedNFTs.length > 0 ? (
             <Row>{renderOwnedNFTs()}</Row>
         ) : (
             <Alert variant="dark">You do not own any Nodes.</Alert>
         )}
         </div>
     </Col>
 </Row>
  
     </>
    );
}

export default Dashboard;
