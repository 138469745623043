// src/hooks/useContract.js

import { useEffect, useState } from 'react';
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import NodesABI from '../abis/NodesABI.json'; // ABI of your contract
import SETTINGS from "../SETTINGS";

export const useContract = (provider) => {
    const [collections, setCollections] = useState([]);
    const [ownedNFTs, setOwnedNFTs] = useState([]);

    const fetchCollections = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
        let collectionsArr = [];
        let buyAvailable = false;
        const collections = await contract.getAllCollectionsInfo();
         
        for(let i=0;i<collections.length;i++){
            try{
                const collection = collections[i];
            
            
            const availableToMint = parseInt(collection.maxSupply) - parseInt(collection.minted);
            let buyStatus = false;
            if(!buyAvailable){
                if(availableToMint > 0){
                    buyStatus = true;
                    buyAvailable = true;
                }
            }
            collectionsArr.push({
                name:collection.name,
                symbol:collection.symbol,
                imageURL:collection.imageURL,
                priceUSDTWei: collection.priceUSDT,
                priceUSDT: formatUnits(collection.priceUSDT, 6),
                maxSupply:parseInt(collection.maxSupply),
                minted:parseInt(collection.minted),
                isAvailable: buyStatus,
                availableToMint: availableToMint
            });
        }catch(error){
            console.log("error");
            console.log(error);
        }
        }
        
        setCollections(collectionsArr);
    };


    const fetchOwnedNFTs = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
        
       
        const account = await signer.getAddress();
        const nfts = await contract.getAllNFTsForUser(account);
        let nftsArr = [];
        console.log("nfts");
        console.log(nfts);
        for(let i=0;i<nfts.length;i++){
        const unclaimedRewards = await contract.calculateRewards(nfts[i].collectionAddress, nfts[i].tokenId);
        console.log("unclaimedRewards");
        console.log(unclaimedRewards.toString());
        nftsArr.push(
            {
                collectionAddress: nfts[i].collectionAddress,
                tokenId:nfts[i].tokenId.toString(),
                imageURL:nfts[i].imageURL,
                name:nfts[i].name,
                unclaimedRewards:formatUnits(unclaimedRewards.toString(), 18),
            }
        );

        
        }
        setOwnedNFTs(nftsArr);
    };

    useEffect(() => {
        if (!provider) return;

        

      

        
        fetchCollections();
        fetchOwnedNFTs();
    }, [provider]);

    return { collections, ownedNFTs, fetchCollections, fetchOwnedNFTs };
};
