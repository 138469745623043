
const SETTINGS = {
    nodeContract:"0xa0bA12B162B1055dD73da28Dc0c6AEE96CC775eB",
    mainContract:"0x30b94450897e51B94a3CacB54f456256eb708cf9",
    usdtAddress:"0x062008718aA448997291bA45DC4DD373594e9346",
    tokenAddress:"0x381564D11D95ecf25a93D4cE527716B3c432D397",

    nativeSymbol: "AVAX",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    tokenSymbol: "SPACEM",

};
export default SETTINGS;

/*

I have 27500000000 tokens for rewards. Once a day for 1826 days I want to distribute 15060240 tokens.
60 % of those daily tokens (9036144) goes for rewards to all exiting nodes evenly. and 40 % goes to staking pool.
How can I do that in this contract?

Each nft unlocks 451 tokens per day for 1826 days getting 825000 all together per nft. User has to claim reward when he wants.

27500000000 - 55% - NODE REWARDS
16500000000 NODES vse skupaj
825000 en node vse skupaj
451 na dan

Days: 1826
Rew per day: 15060240 
60% 9036144 NODES
10% 1506024 BURN
10% 1506024 STAKING
10% 1506024 Marketing
10% 1506024 NAGRADE



55 % VSEH toknov 27.500.000.000 $SPACEM toknov se bo razdelilo med lastnike NODE v roku 5 let / 60 mesecev oz v 1826 dneh. 
27.500.000.000 / 1826 dni (356 dni x 4 + 1 prestopno 366 ) = 15.060.240,96 toknov 

60 % teh toknov prejmejo lastniki NODEov, to je: 9.036.144,57 $SPACEM toknov na dan, vsak dan!!!
To je potrebno tako sprogramirati: vsak dan gre to med lastnike NODE zgornji znesek.
40 % je razlika, ki ostane med 15.060.240,96 – 9.036.144,57= 6.024.096,38 $SPACEM, ter se razdeli na 4 enake dele (4 x po 10 %)
10 % - 1.506.024,09 $SPACEM - BURN
10 % - 1.506.024,09 $SPACEM - STAKING bazen
10 % - 1.506.024,09 $SPACEM - Marketing, za vzdrževanje projekta – poseben bazen 
10 % - 1.506.024,09 $SPACEM – za NAGRADE, ki se delijo tedensko med lastnike NODE-kot žreb, delamo ročno in objavimo vse nagrajence - 

UROŠ, upam, da bo šlo tole pripraviti na način, ki smo si ga zamislili.
OCENA in PREDVIDEVANJA PROJEKTA SPACE MONKEEZ:

Vsekakor verjamem, da bo projekt noro uspel in da bomo vsi prispevali max, kar vsak od nas zmore in zna. 

Zelo enostavno je predvideti pri začetnem MCAP $196.000, cena kovanca 0,00006 USDT da bo zelo enostavno projekt naredil 10x v zelo kratkem, to pomeni 
2M$ Mcap, in brez večjih težav nato
20 M$ v nekaj mesecih…če prav postavimo vse skupaj, pa imamo pred seboj 1000x projekt – 
200 M$ MCap in če MEME kovanci (kot PEPE, npr) pride do 4 MRD MCap, imamo velike šanse, da udarimo na 1 MRD MCap, kar bi pomenilo – 5.000 x projekt.

Pri vložku 1.000 USDT na dan lansiranja po ceni 0,00006 USDT, bo v primeru 1 MRD MCap ta tisočak vreden natančno 5 mio USDT. Je to možno? Absolutno. Vesel, res vesel bom, ko naredimo 10 x in prodamo prvih 2.000 kos NODE-ov, potem bo vse le še letelo…in meja ni…

Gremooo!

Še v denarju, ki ga bodo prejemali lastniki NODE-ov:

Po ceni 0,00006 (začetna cena ob lansiranju tokna) razdelimo med lastnike NODEov znesek v višini 16.800,00 USDT / mesec, če cena tokna ostane enaka. Ko MCap pride na 2 MIO, bodo razdeljeni kovanci mesečno vredni 168.000,00 USDT, če pa gre MCap na 20 Mio, pa se vsak mesec razdeli 1.68 Mio USD med lastnike NODE-ov. 
*/